<template>
<div>
  <div>
    <div>
      <h3 style="display: inline;" class="mr-4">Logs</h3>
      <button class="btn btn-sm btn-primary" @click="downloadAll">Download All</button>
      <button class="btn btn-sm btn-warning" @click="deleteUserLogs">Delete User Logs</button>
    </div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab :title="r.id" v-for="r in rooms" v-bind:key="r.id">
          <roomlogs ref="roomlogs" :roomId="r.id"/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</div>
</template>

<script>
import { db, database } from '@/services/db';
import { getLog } from "@/services/log";
import roomlogs from "@/components/roomlogs";
let log = getLog('test');

export default {
  name: 'app',
  components: {
    roomlogs
  },
  data() {
    return {
      rooms: [],
      oldRooms: [],
    }
  },
  mounted() {
    log.log("mounted()");

    if (this.$route.query.roomId) {
      this.rooms = [{id:this.$route.query.roomId}];
    } else {
      // Recent rooms
      let yesterday = new Date(new Date().setDate(new Date().getDate() - 10));
      this.$bind("rooms", db.collection("LiveRooms").where("startTime", ">", yesterday).orderBy("startTime"));
    }
  },
  methods: {
    downloadAll() {
      for (let r of this.$refs.roomlogs)
        r.saveLogsIfNotEmpty();
    },
    deleteUserLogs() {
      let userDb = database.ref("Logs");
      userDb.remove();
      for (let r of this.$refs.roomlogs)
        r.deleteUserLogs();
    }
  }
}
</script>

<style scoped>

.log {
  font-family: monospace;
  white-space: nowrap;
}

</style>
